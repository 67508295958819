import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { BarcodeDetector } from "barcode-detector"

export default class extends Controller {
  static targets = ["scanner", "scanResult", "noProductTemplate", "stopped", "running"]

  async connect() {
    this.supportedFormats = await BarcodeDetector.getSupportedFormats()
    this.barcodeDetector = new BarcodeDetector({
      // make sure the formats are supported
      formats: ["ean_13", "ean_8"],
    })
    this.beepSound = new Audio("beep.mp3")
  }

  async start() {
    this.scannerTarget.classList.remove("hidden")
    this.stoppedTarget.classList.add("hidden")
    this.runningTarget.classList.remove("hidden")

    this.camera = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: "environment", }
    })
    this.mediaRecorder = new MediaRecorder(this.camera)
    this.scannerTarget.srcObject = this.camera
    this.startScanner()

    this.requestDataInterval = setInterval(() => {
      if (this.mediaRecorder.state === "recording") {
        this.process()
      }
    }, 200)
  }

  stop() {
    this.stoppedTarget.classList.remove("hidden")
    this.runningTarget.classList.add("hidden")
    this.scannerTarget.classList.add("hidden")

    this.camera.getTracks().forEach((track) => {
      track.stop()
    })
    this.pauseScanner()
  }

  // Private

  async process() {
    let barcodes = await this.barcodeDetector.detect(this.scannerTarget)

    if (barcodes.length > 0) {
      let barcode = barcodes[0]
      this.beep()
      this.pauseScanner()
      let url = `/barcodes/${barcode.rawValue}`

      Rails.ajax({
        type: "get",
        url: url,
        success: (data) => {
          this.addItem(data)
        },
        error: (response) => {
          let result = this.noProductTemplateTarget.content.cloneNode(true);
          result.querySelector(".heading").textContent = response.error.heading
          result.querySelector(".message").textContent = response.error.message
          let children = this.scanResultTarget.children
          if (children.length == 0) {
            this.scanResultTarget.appendChild(result)
          } else {
            children[0].replaceWith(result)
          }
          this.startScanner()
        },
      });
    }
  }

  beep() {
    this.beepSound.play()
  }

  addItem(data) {
    Spree.ensureCart(() => {
      SpreeAPI.Storefront.addToCart(
        data.variant_id, 1, {},
        () => {
          Spree.fetchCart()
          // hack to  ensure that fetching the cart does not interfere with showing the product added modal
          setTimeout(() => {
            Spree.showProductAddedModal(
              data.product_summary
            )
          }, 300)
          this.startScanner()
        },
        () => { console.log("not so great success!") },
      )
    })
  }

  pauseScanner() {
    this.scannerTarget.pause()
    this.mediaRecorder.stop()
    this.scannerTarget.classList.add("disabled")
  }

  startScanner() {
    this.scannerTarget.play()
    // delay the start of the processing to prevent double scanning
    setTimeout(() => {
      this.mediaRecorder.start()
      this.scannerTarget.classList.remove("disabled")
    }, 300)
  }
}
